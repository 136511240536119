<template>
  <NuxtLink
    target="_blank"
    to="https://calendly.com/nir-60/1h-intro"
    class="common btn duration-300 transition font-medium px-8 py-3 rounded-full leading-none text-base border-solid inline-flex items-center bg-white text-black"
  >
    <div>Book your strategy call</div>
    <div class="ms-2 w-4 h-4 bg-green-500 rounded-full circle-animation"></div>
  </NuxtLink>
</template>
